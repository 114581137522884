import type { Bank } from '~/models/bank';
import type { SimpleOffer } from '~/models/simulation/simpleSimulation/SimpleOffer';

export default function useSimulationOffer() {
  const bankStore = useBanksStore();

  const getOfferBank = (offer: SimpleOffer): Bank | null => {
    return bankStore.banks?.find(bank => offer.bankSymbol === bank.symbol || offer.bankName === bank.name) || null;
  };

  return {
    getOfferBank,
  };
}
