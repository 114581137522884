<template>
  <v-row>
    <v-col class="py-0 px-0" cols="12">
      <l-contrast-theme-provider>
        <l-card-menu>
          <template #content>
            <h2 class="headline-2 mb-4">
              Skorzystaj z kalkulatora i sprawdź najlepsze oferty kredytów gotówkowych
            </h2>
            <v-row>
              <v-col cols="12" md="6">
                <l-input-price
                    outlined
                    :model-value="store.loanAmount.value"
                    @blur="setLoanAmount"
                    @change="onInputChange"
                    :append-inner-icon="null"
                    :prepend-inner-icon="null"
                    label="Kwota kredytu"
                    :text-center="false"
                    :delay-masked="true"
                  />
              </v-col>
              <v-col cols="12" md="6" class="pb-0 md:pb-3">
                <l-input
                    :model-value="store.loanPeriod.value"
                    label="Okres kredytu"
                    suffix="mies."
                    type="number"
                    @update:model-value="setLoanPeriod"
                    @change="onInputChange"/>
              </v-col>
            </v-row>
          </template>
          <template #action>
            <v-row class="items-end">
              <v-col cols="12">
                <div v-if="props.bestOffer">
                  <span class="headline-3">Kredyt gotówkowy</span>
                  <br/>
                  już od
                  <span class="headline-2"> {{ props.bestOffer.installment }} zł/mies.</span>
                </div>
                <div v-else>
                  <span class="headline-3">Brak wyników</span>
                </div>
              </v-col>
              <v-col cols="12">
                <l-btn
                    block
                    size="x-large"
                    type="primary"
                    @click="showOffers">
                  Przelicz oferty
                </l-btn>
              </v-col>
            </v-row>
          </template>
        </l-card-menu>
      </l-contrast-theme-provider>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import LCardMenu from "~/components/LComponents/LCard/LCardMenu.vue";
import {useCashSimpleSimulationStore} from "~/models/simulation/simpleSimulation/SimpleSimulationInput";
import LContrastThemeProvider from "~/components/LContrastThemeProvider.vue";
import type {SimpleOffer} from "~/models/simulation/simpleSimulation/SimpleOffer";
import useScrollTo from "~/service/useScrollTo";
import {wait} from '~/service/Utils';

const MAIN_CONTENT_SELECTOR = '#main-content';

const emit = defineEmits(['fetch',]);
const props = defineProps<{
  bestOffer: SimpleOffer,
}>();

const store = useCashSimpleSimulationStore();
const {scrollTo,} = useScrollTo();

const onInputChange = (event: Event) => {
  const target: HTMLElement = event.target as HTMLElement;
  if (target) {
    target.blur();
  }
};

const setLoanAmount = (newValue: number) => {
  store.setLoanAmount(newValue);
};

const setLoanPeriod = (newValue: number) => {
  store.setLoanPeriod(newValue);
};

const showOffers = async() => {
  emit('fetch');
  await wait(500);
  scrollTo(MAIN_CONTENT_SELECTOR);
};
</script>

<style scoped>
</style>
