<template>
  <div class="md:mt-4 mb-6 mt-0" v-bind="$attrs">
    <ComparerWidgetHeaderMortgage v-if="isMortgage" @fetch="fetchOffers"/>
    <ComparerWidgetHeaderCash v-else-if="isCash" @fetch="fetchOffers" :best-offer="bestCashOffer"/>
  </div>
  <div id="main-content">
    <v-row v-if="showError">
      <v-col class="text-center headline-2 text-error mt-4">
        Nie udało się wczytać symulacji :(
      </v-col>
    </v-row>
    <template v-else>
      <v-row class="headline-2 mt-2">
        <v-col>
          {{ offers?.length }} Ofert kredytu
          {{ isMortgage ? 'hipotecznego' : 'gotówkowego' }}
        </v-col>
      </v-row>
      <v-progress-linear color="primary" :active="isRequestPending" :indeterminate="true"/>
      <l-contrast-theme-provider>
        <template v-for="(offer, index) in offers" :key="offer.bankSymbol">
          <SimpleOfferCard
              :id="`loanApp-${offer.bankSymbol}`"
              :index="index"
              :offer="offer"
              class="my-5"
              :type="props.type"
              :loanPeriod="simulationStore.installmentsCount.value"
              :bank="getOfferBank(offer)"
              :data-cy="$testids.COMPARE_WIDGET.OFFER_CARD"
            >
            <template #actions>
              <div class="flex max-sm:flex-col sm:flex-row xs:justify-end">
                <l-btn v-if="!creditWorthinessView && !query.utm_owner && isMortgage"
                    type="default"
                    class="max-sm:w-full"
                    @click="goToArrangingConsultationWithOfferAndInterest(offer, CustomerInterest.VERIFICATION_OF_ABILITY)">
                    Zbadaj zdolność
                    <span class="max-md:hidden">kredytową</span>
                </l-btn>
                <l-btn type="primary" class="max-sm:mt-4 sm:!ml-4 max-sm:w-full"
                      @click="goToArrangingConsultationWithOfferAndInterest(offer, CustomerInterest.MEETING_WITH_EXPERT)">
                  {{
                    isMortgage ? 'Umów konsultację online' : 'Zapytaj o kredyt'
                  }}
                </l-btn>
              </div>
            </template>
            <template #offerTitle="{readonly, offer}" v-if="type === ProductType.MORTGAGE && !isLendiget">
              <nuxt-link :to="readonly ? undefined : offerRoute(offer)">
                <h3 :class="{ underline: !readonly }"
                    class="text-medium-emphasis body-1">
                  {{ offer.title }}
                </h3>
              </nuxt-link>
            </template>
          </SimpleOfferCard>
          <l-contrast-theme-provider v-if="index === 0">
            <SimulationOfferDisplayBestOfferAdditionalInfo />
          </l-contrast-theme-provider>
          <template v-if="index === 2">
            <slot/>
          </template>
        </template>
      </l-contrast-theme-provider>
    </template>
  </div>
</template>

<script lang="ts" setup>
import {useSimpleSimulation, type UseSimpleSimulation} from '~/service/widgets/useSimpleSimulation';
import LContrastThemeProvider from '~/components/LContrastThemeProvider.vue';
import useGTM, {GTMActivityEnum} from '~/service/3thParty/useGTM';
import type {SimpleOffer} from '~/models/simulation/simpleSimulation/SimpleOffer';
import {CustomerInterest} from '~/enums/Interest';
import {
  useCashSimpleSimulationStore,
  useMortgageSimpleSimulationStore
} from '~/models/simulation/simpleSimulation/SimpleSimulationInput';
import {ProductType, type SimulationProductType} from '~/enums/ProductType';
import ComparerWidgetHeaderMortgage from '~/components/widgets/ComparerWidget/headers/ComparerWidgetHeaderMortgage.vue';
import ComparerWidgetHeaderCash from '~/components/widgets/ComparerWidget/headers/ComparerWidgetHeaderCash.vue';
import type {LendiQuery, MeetingOrVerificationCustomerInterest, Utms} from '~/models/UTMs/UTMs';
import type {RouteLocationRaw} from "vue-router";
import {encodeUrlParam} from "~/service/Utils";
import {useConsultationQuery} from "~/composables/useConsultationQuery";
import useSimulationOffer from '~/service/useSimulationOffers';

const {getOfferBank,} = useSimulationOffer();

const props = defineProps<{
  utms?: Partial<Utms>,
  type: SimulationProductType,
  targetRouteNameBase?: string,
  openNewTabOnSubmit?: boolean,
}>();

const route = useRoute();
const {goToTerminKonsultacjiOferta} = useGoTo();
const {onComparerWidgetSubmit: sendGTM,} = useGTM();
const {query,} = useQuery<LendiQuery>();
const {
  fetchOffers,
  offers,
  pending: isRequestPending,
  error: simulationError,
}: UseSimpleSimulation = await useSimpleSimulation(props.type);
const {isLendiget} = useConsultationQuery();

const targetRouteNameBase: string = props.targetRouteNameBase ?? route.name as string

const isMortgage = computed<boolean>(() => props.type === ProductType.MORTGAGE);
const isCash = computed<boolean>(() => props.type === ProductType.CASH);

const simulationStore = isMortgage.value
    ? useMortgageSimpleSimulationStore()
    : isCash.value
        ? useCashSimpleSimulationStore()
        : null;

const bestCashOffer= computed<Nullable<SimpleOffer>>(() => isCash.value && offers.value
        ? offers.value.sort((a, b) => a.installment > b.installment)[0]
        : null
);

const showError = computed<boolean>(() => Boolean(simulationError.value) || offers.value?.length === 0);
const creditWorthinessView = computed<boolean>(() => route.fullPath.includes('kalkulator-zdolnosci-kredytowej'));

const goToArrangingConsultationWithOfferAndInterest = (offer: SimpleOffer,
                                                       customerInterest: MeetingOrVerificationCustomerInterest) => {
  sendGTM(customerInterest === CustomerInterest.VERIFICATION_OF_ABILITY ?
      GTMActivityEnum.CHECK_CREDITABILITY :
      GTMActivityEnum.CREATE_ACTIVITY);

  goToTerminKonsultacjiOferta({
    offer: offer,
    currentRouteName: targetRouteNameBase,
    customerInterest: customerInterest,
    loanAmount: simulationStore.loanAmount.value,
    loanPeriod: simulationStore.loanPeriod.value,
    utms: props.utms,
    openInNewTab: props.openNewTabOnSubmit
  })
};

const offerRoute = (offer: SimpleOffer): RouteLocationRaw => {
  return {
    name: `kredyt-hipoteczny-bankSeoName`,
    params:{
      bankSeoName: encodeUrlParam(offer.bankName),
    },
    query,
  };
};

</script>
<style scoped lang="scss">
</style>
